/* eslint-disable react/prop-types */
import { Outlet, ScrollRestoration } from "react-router-dom";
import Footer from "./components/shared/Footer";
import MainNav from "./components/shared/MainNav";
import TopNav from "./components/shared/TopNav";

function App({ children }) {
  return (
    <>
      {/* <TopNav /> */}
      <MainNav />
      <Outlet className=" min-h-screen">{children}</Outlet>
      <ScrollRestoration />
      <Footer />
    </>
  );
}

export default App;
