import { Link } from "react-router-dom";
import chooseUs from "../../assets/choose.png";
import Tilt from "react-parallax-tilt";

const WhyChooseUs = () => {
  const whyChoseData = [
    {
      serial: "01.",
      title: "Commitment to Excellence",
      details:
        "Clients are drawn to our services due to our unwavering commitment to excellence. We consistently deliver results that surpass expectations, fostering long-term partnerships built on trust and exceptional performance.",
    },
    {
      serial: "02.",
      title: "Unmatched Expertise",
      details:
        "Clients choose our services for our unparalleled expertise, ensuring that their needs are met with a high level of proficiency and industry knowledge.",
    },
    {
      serial: "03.",
      title: "Exemplary Collaboration",
      details:
        "Our distinguished track record of fostering successful collaborations is a key factor in client decisions. We prioritize effective communication, understanding client objectives, and working seamlessly to achieve shared goals.",
    },
  ];
  return (
    <section className="lg:py-12 2xl:py-24 bg-[#010013]">
      <div className="container mx-auto px-4 lg:px-8">
        <div className="text-left lg:space-y-4 space-y-3 2xl:space-y-6 flex flex-col justify-center">
          <p className=" text-base font-medium lg:font-bold text-[#fa6f3c] mt-5">
            WHY CHOOSE US
          </p>
          <h1 className="text-accent font-medium text-2xl lg:text-4xl text-left">
            Why Our Esteemed Clients Collaborate with Our Services
            {/* Why Our Customers <br /> Choose With Us
              <span className=" text-[#fa6f3c]">Working</span> */}
          </h1>
          <h3 className="text-sm lg:text-lg max-w-5xl mt-7 text-slate-200">
            Our esteemed clientele chooses to engage in collaborative endeavors
            with our distinguished services, underpinned by unparalleled
            expertise and commitment to excellence.
          </h3>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 lg:gap-7 items-center">
            <div className="space-y-5">
              {whyChoseData.map((whyChose) => (
                <div className="flex gap-3 lg:gap-4">
                  <h2 className=" text-[#fa6f3c] text-2xl lg:text-5xl font-bold">
                    {whyChose?.serial}
                  </h2>
                  <div className="">
                    <h3 className=" font-bold text-xl lg:text-2xl text-white">
                      {whyChose?.title}
                    </h3>
                    <p className=" text-gray-400">{whyChose?.details}</p>
                  </div>
                </div>
              ))}

              <button className="button w-full mt-7">
                <Link to={"/contact"}>Get in touch</Link>
              </button>
            </div>
            <div className="py-8 lg:py-0">
              <Tilt className="parallax-effect" perspective={500}>
                <div className=" relative flex items-center justify-center">
                  <img
                    src={chooseUs}
                    alt="ai image"
                    className=" lg:w-[450px] 2xl:w-[650px]"
                  />
                </div>
              </Tilt>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
