import about from "../../assets/about.png";
import { FaAward } from "react-icons/fa";
import Tilt from "react-parallax-tilt";
import { Link } from "react-router-dom";

const AboutUs = () => {
  const aboutUsData = [
    {
      title: "Innovation Leadership",
      details:
        "We are committed to leading the industry with cutting-edge solutions, consistently pushing the boundaries of innovation and staying at the forefront of technological trends.",
    },
    {
      title: "Reliable Performance",
      details:
        "We ensure the consistent and reliable performance of our products or services, offering solutions that meet or exceed our customers' expectations and operate seamlessly.",
    },
    {
      title: "Scalability and Adaptability",
      details:
        "We design solutions that can scale with the needs of our clients and swiftly adapt to emerging technological landscapes, providing a foundation for growth and flexibility.",
    },
    {
      title: "Security and Privacy",
      details:
        "We prioritize the security and privacy of user data, implementing robust measures to safeguard information. This commitment instills trust and confidence in our clients regarding the handling of sensitive dat",
    },
  ];

  return (
    <section className="px-4 py-8 lg:py-12 2xl:py-24 bg-[#01002B]">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-2 2xl:gap-5 items-center">
          <div className="lg:w-[45%]">
            <Tilt className="parallax-effect" perspective={500}>
              <div className=" relative">
                <img
                  src={about}
                  alt="ai image"
                  className=" lg:w-[450px] 2xl:w-[650px]"
                />
              </div>
            </Tilt>
          </div>

          <div className="lg:w-[55%] text-left lg:space-y-4 space-y-3 2xl:space-y-6 flex flex-col justify-center">
            <p className=" text-base font-medium lg:font-bold text-[#fa6f3c] mt-3 lg:mt-5">
              ABOUT US
            </p>

            <h1 className="text-accent font-semibold text-2xl lg:text-5xl 2xl:text-6xl text-left">
              Establishing trust globally
              <br />
              Since <span className=" text-[#fa6f3c]"> 2020</span>.
            </h1>

            <h3 className="text-sm lg:text-xl lg:font-medium mt-7 text-slate-300">
              Forging an international legacy of trust in technology where
              innovation meets reliability. Forging an international legacy of
              trust in technology where innovation meets reliability.
            </h3>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-8 mb-5">
              {aboutUsData.map((about) => (
                <div className=" flex items-start gap-3 lg:gap-4">
                  <div className=" bg-[#fa6f3c] bg-opacity-20 px-4 py-4 rounded-lg">
                    <FaAward
                      color="#fa6f3c"
                      className="text-base lg:text-3xl"
                    />
                  </div>
                  <div>
                    <h3 className=" font-medium text-xl lg:text-2xl text-white">
                      {about?.title}
                    </h3>
                    <p className=" text-gray-400">{about?.details}</p>
                  </div>
                </div>
              ))}
            </div>

            <button className="button">
              <Link to={"/contact"}>Get in touch</Link>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
