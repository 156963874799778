import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import required modules
import { Pagination, Navigation } from "swiper/modules";

const Testimonial = () => {
  return (
    <div
      style={{
        backgroundImage: 'url("/testi.png")',
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className=" bg-fixed h-[100vh]"
    >
      <div className="flex justify-center h-full items-center bg-opacity-70">
        <div className=" text-center">
          <p className=" text-center font-bold text-[#fa6f3c] mt-5">
            TESTIMONIALS
          </p>
          <h1 className="text-center font-extrabold lg:text-6xl text-4xl mb-10">
            Customers Testmonials
          </h1>
          <div className=" container mx-auto">
            <Swiper
              slidesPerView={1}
              spaceBetween={30}
              loop={true}
              navigation={true}
              modules={[Pagination, Navigation]}
              className="mySwiper"
            >
              <p className=" max-w-5xl px-2 mx-auto lg:text-lg text-white mb-7">
                SoftKarrot played a pivotal role in realizing our vision for
                Hellobazar.
                <br />
                Throughout our collaboration, SoftKarrot demonstrated
                professionalism, innovation, and a deep commitment to quality.
                Led by knowledgeable and proactive individuals, their team
                consistently exceeded our expectations, delivering solutions
                that surpassed industry standards.
                <br />
                With SoftKarrot's support, we introduced groundbreaking features
                like the Reselling Platform, Affiliate Platform, and BechteChai.
                These innovations have propelled Hellobazar to the forefront of
                the e-commerce industry in Bangladesh.
                <br />
                We are immensely grateful for SoftKarrot's partnership and look
                forward to continued success together.
              </p>
              <h3 className=" text-xl font-bold text-white">Shamim Ahmed</h3>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
