import AboutUs from "../components/home/AboutUs";
import Banner from "../components/home/Banner";
import Credential from "../components/home/Credential";
import DedicatedTeam from "../components/home/DedicatedTeam";
import Price from "../components/home/Price";
import Testimonial from "../components/home/Testimonial";
import WhyChooseUs from "../components/home/WhyChooseUs";

const Home = () => {
  return (
    <>
      <Banner />
      <AboutUs />
      <WhyChooseUs />
      <Credential />
      <DedicatedTeam />
      <Testimonial />
      {/* <Price /> */}
    </>
  );
};

export default Home;
