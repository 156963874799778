import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import MobileMenu from "./MobileMenu";
import { BiMenuAltRight } from "react-icons/bi";
import { IoMdClose } from "react-icons/io";

const MainNav = () => {
  const [open, setOpen] = useState(false);
  const navlinks = [
    {
      path: "/",
      title: "Home",
    },
    {
      path: "/about",
      title: "About",
    },
    {
      path: "/works",
      title: "Works",
    },
    {
      path: "/services",
      title: "Services",
    },
    // {
    //   path: "/team",
    //   title: "Team",
    // },
    {
      path: "/contact",
      title: "Contact",
    },
  ];

  return (
    <header
      style={{ position: "-webkit-sticky", top: "20px" }}
      className="container mx-auto z-20 sticky top-0"
    >
      <div className="bg-white rounded py-2 2xl:py-2.5 px-2 lg:px-12 mx-2 lg:mx-8">
        <div className=" flex items-center justify-between">
          <div className="">
            {/* <h1 className=" text-3xl text-black font-bold">
              Soft <span className=" text-[#fa6f3c]">Karrot</span>
            </h1> */}
            <Link to={"/"}>
              <img
                src="/slogo.png"
                className="w-40 lg:w-44 h-10 lg:h-12"
                alt=""
              />
            </Link>
          </div>

          <div className="hidden lg:block">
            <ul className=" flex gap-10">
              {navlinks.map(({ path, title }) => (
                <li key={path} className="relative group custom-link">
                  <NavLink
                    className={`font-bold text-black group-hover:text-[#fa6f3c] duration-300 text-base 2xl:text-xl`}
                    to={path}
                  >
                    {title}
                  </NavLink>
                </li>
              ))}
            </ul>
          </div>

          {/* <div className=" w-[20%] text-right">
          <button className=" bg-[#fa6f3c] text-white font-medium px-10 py-3 hover:bg-transparent hover:border-2 border-[#fa6f3c] border-2 duration-200 rounded-tr-xl rounded-bl-xl text-lg hover:text-black">
            Appointment
          </button>
        </div> */}

          <div className="lg:hidden" onClick={() => setOpen(!open)}>
            {open ? (
              <IoMdClose size={27} className="text-black" />
            ) : (
              <BiMenuAltRight size={35} className="text-black" />
            )}
          </div>
          {/* mobile menu  */}
          <div
            className={` absolute duration-100 ease-out ${
              open
                ? "left-0 top-[56px] md:-left-[30px] w-full"
                : "-left-[220px] top-[56px] bottom-0 md:-left-[220px]"
            }`}
          >
            <MobileMenu setOpen={setOpen} />
          </div>
        </div>
      </div>
    </header>
  );
};

export default MainNav;
