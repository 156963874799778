import { AiOutlineHome } from "react-icons/ai";
import { Link, useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

const ProjectDetails = () => {
  const { id } = useParams();

  const projects = [
    {
      id: 1,
      name: "Online Store",
      details:
        "A web-based e-commerce platform for buying and selling products.",
      technology_used: [
        "HTML",
        "CSS",
        "JavaScript",
        "React",
        "Node.js",
        "MongoDB",
      ],
      image:
        "https://www.geeksinvention.com/assets/v3/works/equity/equity-hero.webp",
    },
    {
      id: 2,
      name: "Task Management App",
      details: "An application for managing and tracking tasks within a team.",
      technology_used: ["Angular", "Spring Boot", "MySQL"],
      image:
        "https://www.geeksinvention.com/assets/v3/works/travel/travel-hero.webp",
    },
    {
      id: 3,
      name: "Mobile Fitness Tracker",
      details: "A mobile app that tracks and analyzes fitness activities.",
      technology_used: ["Swift", "Kotlin", "Firebase"],
      image:
        "https://www.geeksinvention.com/assets/v3/works/ewallet/wallet-hero.webp",
    },
    {
      id: 4,
      name: "Data Visualization Dashboard",
      details:
        "A dashboard for visualizing and analyzing data using charts and graphs.",
      technology_used: ["D3.js", "Python", "Flask", "SQLAlchemy"],
      image:
        "https://www.geeksinvention.com/assets/v3/works/workout/workout-hero.webp",
    },
  ];

  const project = projects.find((p) => p.id === id);

  return (
    <section className="-mt-16 lg:-mt-16 2xl:-mt-16 pb-20 pt-32 bg-[#01002B]">
      <div className="container mx-auto px-4 lg:px-12">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-0 pb-6 lg:pb-10">
          {/* this is title and link div  */}
          <div className="flex pl-20 flex-col justify-center">
            <h2 className="text-4xl font-medium pb-2">Hellobazar</h2>
            <a href="https://www.hellobazar.app" target="_blank" class="hover:underline hover:text-blue-500 hover:cursor-pointer">
              <h2 class="text-blue-500">www.hellobazar.app</h2>
            </a>
          </div>
          {/* this is video div  */}
          <div className="">
            <iframe
              className="rounded-lg w-full lg:w-[550px] h-[200px] lg:h-[320px]"
              title="YouTube Video"
              src={`https://www.youtube.com/embed/PvbRgp2sH0U`}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </div>
        <div className="pb-6 lg:pb-10 space-y-4 text-lg 2xl:text-xl">
          <p>
            Hellobazar is rapidly emerging as the premier e-commerce platform in
            Bangladesh, offering a comprehensive array of services tailored to
            meet diverse consumer needs. With its multifaceted approach,
            Hellobazar combines elements of reselling, affiliate marketing, and
            social networking to create a dynamic online marketplace.
          </p>
          <p>
            One of the standout features of Hellobazar is its Reselling
            Platform, which empowers individuals to become resellers after
            completing specialized training. Upon successful completion,
            resellers gain access to a wide range of products that they can
            customize and market at their preferred prices. Each sale yields a
            reselling income, reflecting the margin by which the reseller
            increases the product price. Furthermore, resellers have the
            opportunity to expand their earning potential by sharing their
            referral links. Through these links, users can join the platform,
            with the referrer earning 50% of the training fee and additional
            income from their referred team members.
          </p>
          <div className="grid grid-cols-1 lg:grid-cols-2 pt-6 gap-6 lg:gap-0">
            <p>
              The Affiliate Platform is another key aspect of Hellobazar's
              ecosystem, enabling participants to monetize their online presence
              by promoting products to a wider audience. Similar to the
              Reselling Platform, individuals must undergo training before they
              can begin sharing product links. For every purchase made through
              their affiliate links, participants earn a commission equivalent
              to 12% of the product's base price. These earnings are deposited
              directly into the user's wallet, providing a convenient means of
              tracking and managing their income.
            </p>
            <div className="lg:h-[50dvh] lg:px-4">
              <Swiper
                pagination={{
                  dynamicBullets: true,
                }}
                modules={[Pagination]}
                className="mySwiper"
              >
                <SwiperSlide>
                  <img src="/001.png" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src="/002.png" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src="/003.png" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src="/004.png" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src="/005.png" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src="/006.png" alt="" />
                </SwiperSlide>
                <SwiperSlide>
                  <img src="/007.png" alt="" />
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
          <p>
            In addition to its commercial endeavors, Hellobazar also facilitates
            peer-to-peer transactions through its "Bechte chai" feature,
            allowing users to buy and sell used goods with ease. This service
            promotes sustainability and resourcefulness while fostering a sense
            of community among users.
          </p>
          <p>
            Furthermore, Hellobazar serves as a hub for social engagement
            through its "Blood Club" feature, which enables individuals to
            register their blood types and connect with potential donors within
            their local area. This life-saving initiative underscores
            Hellobazar's commitment to leveraging technology for the greater
            good, highlighting its role as not just a marketplace but also a
            platform for social impact.
          </p>
          <p>
            Overall, Hellobazar stands out as a versatile and inclusive
            e-commerce platform, offering a diverse range of services that cater
            to the evolving needs of its users. By combining elements of
            commerce, community, and social responsibility, Hellobazar is poised
            to become a cornerstone of Bangladesh's digital landscape.
          </p>
        </div>
        <div className=" text-center">
          <h1 className="text-center lg:text-4xl text-3xl mb-10">
            Have a project in mind? <br />
            Lets get to work.
          </h1>
          <Link to="/contact">
            <button className="button">Contact Us</button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default ProjectDetails;
