const DedicatedTeam = () => {
  const teamData = [
    {
      imageUrl: "/Agile approach.png",
      text: "Agile approach",
    },
    {
      imageUrl: "/Deep tech expertise.png",
      text: "Deep tech expertise",
    },
    {
      imageUrl: "/personal.png",
      text: "Personal commitment",
    },
    {
      imageUrl: "/Scalability.png",
      text: "Scalability",
    },
    {
      imageUrl: "/Regular reporting.png",
      text: "Regular reporting",
    },
    {
      imageUrl: "/Time tracking.png",
      text: "Time tracking",
    },
  ];
  return (
    <section className="py-8 lg:py-12 2xl:py-24 bg-[#180023]">
      <div className="container mx-auto px-4 lg:px-8">
        <div className="">
          <h1 className="font-medium lg:text-4xl text-3xl mb-4 lg:mb-6">
            <span className=" text-[#fa6f3c]">Dedicated</span> team members
          </h1>
          <p className="lg:pr-12 lg:text-xl">
            With unwavering commitment, our team channels 100% concentration
            towards your project, providing you with absolute control over the
            strategic management of dedicated team members.
          </p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-3 gap-8 lg:gap-16 2xl:gap-20 mt-10 lg:mt-14 items-center container mx-auto">
          {teamData.map((singleTeam) => (
            <div className="relative">
              <img
                className="w-full h-full"
                src={singleTeam?.imageUrl}
                alt=""
              />
              <div className="absolute top-0 left-0 z-10 w-full text-center h-full flex items-center justify-center">
                <h3 className=" text-2xl pt-4 font-medium mb-8 relative">
                  {singleTeam?.text}
                </h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default DedicatedTeam;
