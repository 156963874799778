import { Link } from "react-router-dom";

const MobileMenu = ({ setOpen }) => {
  const navlinks = [
    {
      path: "/",
      title: "Home",
    },
    {
      path: "/about",
      title: "About",
    },
    {
      path: "/projects",
      title: "Works",
    },
    {
      path: "/services",
      title: "Services",
    },
    // {
    //   path: "/team",
    //   title: "Team",
    // },
    {
      path: "/contact",
      title: "Contact",
    },
  ];
  return (
    <div className=" bg-black/90 w-full h-screen flex justify-start items-center">
      <div className="bg-[#01002B] w-1/2 h-screen lg:hidden">
        <ul className="flex flex-col justify-between gap-5 px-10 pt-10">
          {navlinks.map((link) => (
            <li
              onClick={() => setOpen((prev) => !prev)}
              key={link.path}
              className="  text-white font-medium duration-300 text-left"
            >
              <Link to={link.path}>{link.title}</Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default MobileMenu;
