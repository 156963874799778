import { Link } from "react-router-dom";

const Services = () => {
  const teamData = [
    {
      imageUrl: "/Agile approach.png",
      text: "Agile approach",
      details:
        "Our commitment to excellence is exemplified through our Agile approach. By embracing agility in our processes, we ensure rapid adaptability to evolving client needs, fostering collaborative and dynamic solutions for unparalleled project success.",
    },

    {
      imageUrl: "/personal.png",
      text: "Personal commitment",
      details:
        "We prioritize transparency and accountability through our meticulous performance reporting. Our clients benefit from comprehensive and insightful reports, providing real-time visibility into project progress and outcomes. This commitment to data-driven insights empowers informed decision-making for mutual success.",
    },
    {
      imageUrl: "/Regular reporting.png",
      text: "Regular reporting",
      details:
        "We uphold a commitment to regular and transparent communication. Our clients can expect consistent updates and open channels, ensuring a seamless exchange of information, fostering collaboration, and promoting a shared understanding of project dynamics.",
    },
    {
      imageUrl: "/Scalability.png",
      text: "Scalability",
      details:
        "We embrace the ethos of continuous delivery. Our streamlined processes and cutting-edge methodologies ensure a seamless and uninterrupted flow of updates, features, and improvements, allowing our clients to experience the benefits of ongoing progress and innovation throughout the project lifecycle.",
    },

    {
      imageUrl: "/Deep tech expertise.png",
      text: "Deep tech expertise",
      details:
        "Our strategic and systematic approach ensures smooth transitions, minimizes disruptions, and optimizes organizational efficiency. By carefully navigating changes, we empower our clients to adapt, evolve, and thrive in dynamic business landscapes.",
    },
    {
      imageUrl: "/Time tracking.png",
      text: "Time tracking",
      details:
        "We provide personalized assistance to cater to the unique needs of our clients. Our commitment to delivering tailored solutions ensures that our clients receive individualized support, fostering a collaborative partnership that goes beyond standard services.",
    },
  ];

  const serviceSuite = [
    {
      title1: "Web",
      title2: "Development",
      imageUrl:
        "https://www.geeksinvention.com/assets/pm/bg-webdev%402x-780fecff3c50542af4b05c0a5a9c317b.png",
      details:
        "Our web development services epitomize innovation and proficiency. We leverage cutting-edge technologies to craft dynamic and responsive websites, ensuring a seamless user experience and aligning with the evolving digital landscape. Elevate your online presence with our expert web development solutions.",
    },
    {
      title1: "Project",
      title2: "Management",
      imageUrl:
        "https://www.herzing.edu/sites/default/files/styles/fp_960_640/public/2020-09/project-management-skills.jpg.webp?itok=4Nbi1ecA",
      details:
        "Our project management services are characterized by precision and efficiency. We employ robust methodologies to orchestrate seamless coordination, meticulous planning, and proactive risk mitigation. Entrust your projects to us, and experience a structured and results-driven approach that ensures successful outcomes.",
    },
    {
      title1: "Quality",
      title2: "Assurance",
      imageUrl:
        "https://cdn.elearningindustry.com/wp-content/uploads/2023/07/Shutterstock_2239497801.jpg",
      details:
        "We uphold unwavering standards of quality assurance. Our dedicated team meticulously scrutinizes every aspect of our products and services to ensure they meet the highest benchmarks. Trust us to deliver solutions that not only meet but exceed expectations, adhering to rigorous quality assurance practices throughout the development lifecycle.",
    },
    {
      title1: "Android",
      title2: "Development",
      imageUrl:
        "https://solguruz.com/wp-content/uploads/2023/11/Top-19-Android-App-Development-Trends.png",
      details:
        "Our Android development services epitomize innovation and excellence. Leveraging the latest technologies and industry best practices, we craft robust and user-friendly Android applications. Whether it's creating immersive user experiences or implementing cutting-edge features, our team is dedicated to delivering high-quality Android solutions tailored to meet your unique requirements.",
    },
    {
      title1: "UI/UX",
      title2: "Design",
      imageUrl:
        "https://img.freepik.com/free-vector/gradient-ui-ux-background_23-2149052117.jpg?w=740&t=st=1708779959~exp=1708780559~hmac=59ef61334d973265005a40942fc5ee4365682a6ed62e0644e7952fa0c5aea513",
      details:
        "We specialize in UI/UX design that transcends aesthetics and functionality. Our design philosophy revolves around creating intuitive and visually captivating user interfaces, ensuring an exceptional user experience. From wireframes to polished designs, we meticulously craft solutions that not only meet but exceed user expectations, enhancing the overall usability and appeal of your digital products.",
    },
    {
      title1: "IOS",
      title2: "Development",
      imageUrl:
        "https://cdn-idnhn.nitrocdn.com/pGZJrsaaNAeqFgzcqIWGRFXLaVNbxMer/assets/images/optimized/rev-fd0af30/netsmartz.com/wp-content/uploads/2022/12/Mobile-app-development-1.jpg",
      details:
        "Our iOS development services epitomize a blend of innovation and precision. Utilizing the latest Apple technologies and industry best practices, we engineer seamless and high-performance iOS applications. Our dedicated team ensures the delivery of sleek and user-centric solutions, tailored to meet the unique demands of the iOS platform, fostering an exceptional user experience for your audience.",
    },
  ];
  return (
    <section className="bg-[#01002B]">
      <section
        className={`2xl:-mt-20 -mt-16 2xl:h-[50vh] lg:h-[50vh] flex justify-center items-end pt-28 lg:pt-0 pb-12 2xl:pb-20`}
      >
        <div>
          <h1 className="text-center font-normal lg:text-5xl font-serif text-primary text-4xl lg:mb-6">
            Perfect Service Suite
          </h1>
          <p className=" text-center w-3/5 mx-auto">
            We offer iOS, Android, and web development services together with
            quality assurance and project management. We have all the expertise
            you need to produce a fully-fledged, stable product.
          </p>
        </div>
      </section>

      <div className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-3">
        {serviceSuite.map((service) => (
          <div className="group relative w-full h-[400px] overflow-hidden">
            <div
              className="absolute inset-0 bg-cover duration-300 opacity-0 group-hover:opacity-100 scale-100 group-hover:scale-105 transition-all"
              style={{
                backgroundImage: `url(${service?.imageUrl}),linear-gradient(rgba(0,0,0,0.7),rgba(0,0,0,0.8))`,
                backgroundBlendMode: "multiply",
                backgroundPosition: "center",
              }}
            ></div>
            <div className="absolute inset-0 flex flex-col items-center justify-center">
              <h2 className="text-white text-4xl text-center mb-5">
                {service?.title1} <br /> {service?.title2}
              </h2>
              <p className=" text-center mx-auto w-3/4">{service?.details}</p>
            </div>
          </div>
        ))}
      </div>

      {/* how we work  */}
      <section className="pt-12 lg:pt-20  container mx-auto px-4 lg:px-8 flex flex-col justify-center items-center">
        <div className=" text-center">
          <h1 className="text-center text-primary font-serif font-normal lg:text-5xl text-4xl mb-6">
            How we work
          </h1>
          <p className="px-6 lg:px-20 text-center mx-auto text-xl">
            At SoftKarrot, we cultivate robust client relationships through
            three fundamental pillars: maintaining accountability for our
            projects, ensuring transparency in our processes, and consistently
            delivering exemplary results.
          </p>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-3 2xl:grid-cols-3 gap-4 lg:gap-8 mt-14 items-center container mx-auto">
          {teamData.map((singleTeam) => (
            <div className="relative">
              <img
                className="w-full h-full"
                src={singleTeam?.imageUrl}
                alt=""
              />
              <div className="absolute top-0 left-0 z-10 w-full text-center h-full p-4 flex items-center justify-center flex-col">
                <h3 className=" text-xl font-medium mb-1 relative">
                  {singleTeam?.text}
                </h3>
                <p className="text-sm text-center text-pretty">
                  {singleTeam?.details}
                </p>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* learn more section  */}
      <section className="2xl:h-[65vh] lg:h-[60vh] pt-10 lg:pt-0 flex justify-center items-center">
        <div className=" text-center px-4">
          <h1 className="text-center font-normal lg:text-4xl 2xl:text-5xl text-3xl mb-4 lg:mb-10">
            Learn more about SoftKarrot
          </h1>
          <Link to="/projects">
            <button className=" bg-[#fa6f3c] text-white font-medium px-10 lg:py-2 2xl:py-3 hover:bg-transparent hover:border-2 border-[#fa6f3c] border-2 duration-200 rounded-tr-xl rounded-bl-xl text-lg">
              See our works
            </button>
          </Link>
        </div>
      </section>
    </section>
  );
};

export default Services;
