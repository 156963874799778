import { Link } from "react-router-dom";

const Team = () => {
  const teamMember = [
    {
      imageUrl:
        "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260",
    },
    {
      imageUrl:
        "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260",
    },
    {
      imageUrl:
        "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260",
    },
    {
      imageUrl:
        "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260",
    },
    {
      imageUrl:
        "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260",
    },
    {
      imageUrl:
        "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260",
    },
    {
      imageUrl:
        "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260",
    },
    {
      imageUrl:
        "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260",
    },
  ];
  return (
    <>
      {/* <section
        style={{
          backgroundImage:
            "url(https://i.ibb.co/vw9t6Kf/img-02.png), url(https://i.ibb.co/n7N4Hkz/img-03.png)",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom right, bottom left",
        }}
        className=" 2xl:-mt-[180px] lg:-mt-[160px] 2xl:h-[50vh] lg:h-[65vh] bg-[#040d33] flex justify-start items-end pb-10"
      >
        <div className="container mx-auto">
          <h1 className="text-left font-extrabold lg:text-6xl text-4xl mb-10">
            Our Team
          </h1>
          <p className=" flex items-center gap-3">
            <Link to="/">
              <span className=" flex gap-3 items-center hover:text-[#fa6f3c] font-medium duration-300">
                <AiOutlineHome />
                Home
              </span>
            </Link>{" "}
            | Our Team
          </p>
        </div>
      </section> */}
      <section className="2xl:-mt-20 -mt-16 pt-16 lg:pt-24 pb-12 bg-[#01002B]">
        <div className="container mx-auto">
          {/* <div className=" flex justify-between items-center mb-10">
            <div>
              <p className=" font-bold text-lg text-[#fa6f3c] mb-3">Team</p>
              <h3 className=" text-5xl font-bold">Our Experts Team Members</h3>
            </div>
            <div>
              <button className=" bg-[#fa6f3c] text-white font-medium px-10 py-3 hover:bg-transparent hover:border-2 border-[#fa6f3c] border-2 duration-200 rounded-tr-xl rounded-bl-xl text-lg">
                See more
              </button>
            </div>
          </div> */}
          <div className="pt-14">
            <h1 className="text-center font-normal lg:text-5xl font-serif text-primary text-4xl lg:mb-2">
              Our Team
            </h1>
            <p className="text-slate-200 text-center lg:text-4xl w-3/5 mx-auto">
              Our Experts Team Members
            </p>
          </div>

          <div className="max-w-5xl mx-auto grid grid-cols-2 lg:grid-cols-4 gap-6 lg:gap-12 pt-14">
            {/* <div>
              <div className="relative overflow-hidden transition duration-300 transform rounded shadow-lg lg:hover:-translate-y-2 hover:shadow-2xl">
                <img
                  className="object-cover w-full h-56 md:h-64 xl:h-80"
                  src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&amp;cs=tinysrgb&amp;dpr=3&amp;h=750&amp;w=1260"
                  alt="Person"
                />
                <div className="absolute inset-0 flex flex-col justify-center px-5 py-4 text-center transition-opacity duration-300 bg-black bg-opacity-75 opacity-0 hover:opacity-100">
                  <p className="mb-1 text-lg font-bold text-gray-100">
                    Oliver Aguilerra
                  </p>
                  <p className="mb-4 text-xs text-gray-100">Product Manager</p>
                  <p className="mb-4 text-xs tracking-wide text-gray-400">
                    Vincent Van Gogh’s most popular painting, The Starry Night.
                  </p>
                  <div className="flex items-center justify-center space-x-3">
                    <Link to="">
                      <FaFacebook size={25} />
                    </Link>
                    <Link to="">
                      <FaLinkedinIn size={25} />
                    </Link>
                  </div>
                </div>
              </div>
            </div> */}
            {teamMember.map((member) => (
              <div className="w-full h-full flex flex-col items-center justify-center">
                <img
                  className="w-28 h-28 rounded-full"
                  src={member?.imageUrl}
                  alt=""
                />
                <h3 className="pt-2 font-medium text-lg">Software Engineer</h3>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* learn more section  */}
      <section className="2xl:h-[65vh] bg-[#01002B] lg:h-[60vh] flex justify-center items-center">
        <div className=" text-center">
          <h1 className="text-center font-normal lg:text-4xl 2xl:text-5xl text-4xl mb-10">
            Learn more about SoftKarrot
          </h1>
          <Link to="/projects">
            <button className=" bg-[#fa6f3c] text-white font-medium px-10  lg:py-2 2xl:py-3 hover:bg-transparent hover:border-2 border-[#fa6f3c] border-2 duration-200 rounded-tr-xl rounded-bl-xl text-lg">
              See our works
            </button>
          </Link>
        </div>
      </section>
    </>
  );
};

export default Team;
