const Credential = () => {
  return (
    <>
      {/* <section
        style={{
          backgroundImage: `
          linear-gradient(
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.5)
          ),
          url(https://amincodes.com/html/flex-it/assets/images/sections-bg-images/pattern-bg-3.jpg)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
        className=" 2xl:h-[50vh] lg:h-[65vh] flex justify-center items-center pb-10"
      >
        <div className="container mx-auto grid grid-cols-1 lg:grid-cols-4 2xl:grid-cols-4 gap-7">
          <Tilt className="parallax-effect" perspective={500}>
            <div className=" flex items-center flex-col gap-5 text-center border-[#fa6f3c] border-2 p-8 border-t-[10px] duration-300 w-full rounded-b-lg bg-black bg-opacity-20">
              <FaRegUser size={40} />
              <h1 className=" text-5xl font-semibold text-primary mb-1">240+</h1>
              <p className=" text-xl">Finished Projects</p>
            </div>
          </Tilt>
          <Tilt className="parallax-effect" perspective={500}>
            <div className=" flex items-center flex-col gap-5 text-center border-[#fa6f3c] border-2 p-8 border-t-[10px] duration-300 w-full rounded-b-lg bg-black bg-opacity-20">
              <FaRegUser size={40} />
              <h1 className=" text-5xl font-semibold text-primary mb-1">240+</h1>
              <p className=" text-xl">Finished Projects</p>
            </div>
          </Tilt>
          <Tilt className="parallax-effect" perspective={500}>
            <div className=" flex items-center flex-col gap-5 text-center border-[#fa6f3c] border-2 p-8 border-t-[10px] duration-300 w-full rounded-b-lg bg-black bg-opacity-20">
              <FaRegUser size={40} />
              <h1 className=" text-5xl font-semibold text-primary mb-1">240+</h1>
              <p className=" text-xl">Finished Projects</p>
            </div>
          </Tilt>

          <Tilt className="parallax-effect" perspective={500}>
            <div className=" flex items-center flex-col gap-5 text-center border-[#fa6f3c] border-2 p-8 border-t-[10px] duration-300 w-full rounded-b-lg bg-black bg-opacity-20">
              <FaRegUser size={40} />
              <h1 className=" text-5xl font-semibold text-primary mb-1">240+</h1>
              <p className=" text-xl">Finished Projects</p>
            </div>
          </Tilt>
        </div>
      </section> */}
    </>
  );
};

export default Credential;
