import { Link } from "react-router-dom";

const Works = () => {
  const projects = [
    {
      id: 1,
      name: "Online Store",
      details:
        "A web-based e-commerce platform for buying and selling products.",
      technology_used: [
        "HTML",
        "CSS",
        "JavaScript",
        "React",
        "Node.js",
        "MongoDB",
      ],
      image:
        "https://www.geeksinvention.com/assets/v3/works/equity/equity-hero.webp",
    },
    // ,
    // {
    //   id: 2,
    //   name: "Task Management App",
    //   details: "An application for managing and tracking tasks within a team.",
    //   technology_used: ["Angular", "Spring Boot", "MySQL"],
    //   image:
    //     "https://www.geeksinvention.com/assets/v3/works/travel/travel-hero.webp",
    // },
    // {
    //   id: 3,
    //   name: "Mobile Fitness Tracker",
    //   details: "A mobile app that tracks and analyzes fitness activities.",
    //   technology_used: ["Swift", "Kotlin", "Firebase"],
    //   image:
    //     "https://www.geeksinvention.com/assets/v3/works/ewallet/wallet-hero.webp",
    // },
    // {
    //   id: 4,
    //   name: "Data Visualization Dashboard",
    //   details:
    //     "A dashboard for visualizing and analyzing data using charts and graphs.",
    //   technology_used: ["D3.js", "Python", "Flask", "SQLAlchemy"],
    //   image:
    //     "https://www.geeksinvention.com/assets/v3/works/workout/workout-hero.webp",
    // },
  ];

  return (
    <section className="bg-[#01002B] -mt-16 lg:-mt-16 2xl:-mt-[180px] px-4 lg:px-12">
      <h1 className="pt-28 lg:pt-36 text-center font-medium font-serif text-primary lg:text-5xl text-4xl mb-4 relative">
        Our Recent Project
      </h1>

      {/* project section  */}
      <section className="max-w-3xl mx-auto lg:px-20 py-6 lg:py-10 grid grid-cols-1 lg:grid-cols-1 gap-8">
        {projects.map((project, index) => (
          <Link key={index} to={`/projects/${project.id}`}>
            <div className="bg-[#01002B]/50 border border-slate-400 shadow-lg flex items-center justify-center flex-col rounded-xl px-4 py-8">
              <img src="/Hello Bazar 1.png" alt="" />
              <p className="text-xl font-medium pb-2">Hellobazar</p>
              <p className="pb-4 text-center">
                Hellobazar is rapidly emerging as the premier e-commerce
                platform in Bangladesh, offering a comprehensive array of
                services tailored to meet diverse consumer needs. With its
                multifaceted approach, Hellobazar combines elements of
                reselling, affiliate marketing, and social networking to create
                a dynamic online marketplace.
              </p>
              <button className="buttonSm">Read More</button>
            </div>
          </Link>
        ))}
      </section>

      {/* have a project on mind section  */}
      <section className="py-10 lg:py-20 flex justify-center items-center bg-[#01002B] border-b-2 border-b-[#01002B]">
        <div className=" text-center">
          <h1 className="text-center font-normal lg:text-4xl text-3xl mb-10">
            Have a project on mind??
          </h1>
          <Link href="/contact">
            <button className=" bg-[#fa6f3c] text-white font-medium px-10 py-1.5 hover:bg-transparent hover:border-2 border-[#fa6f3c] border-2 duration-200 rounded-tr-xl rounded-bl-xl text-lg">
              Contact us
            </button>
          </Link>
        </div>
      </section>
    </section>
  );
};

export default Works;
