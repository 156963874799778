import Tilt from "react-parallax-tilt";
import heroImg from "../../assets/illustration-1.png";

const Banner = () => {
  return (
    <section
      style={{
        backgroundImage: 'url("https://i.ibb.co/JHhz16j/image.jpg")',
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
      className="h-[73vh] lg:h-screen -mt-14  lg:-mt-16 2xl:-mt-16 px-4 lg:px-20 flex items-center justify-center"
    >
      <div className=" grid grid-cols-1 lg:grid-cols-2 container mx-auto  lg:gap-5 justify-items-center lg:mt-24 2xl:mt-32">
        <div className="text-left lg:space-y-6 space-y-3 flex flex-col justify-center">
          <h1 className="text-accent font-medium lg:text-6xl lg:leading-[64px] text-4xl text-left">
            We believe... <br />
            <span className=" text-[#fa6f3c]">We Can...</span>
          </h1>
          <h3 className="text-lg lg:text-2xl 2xl:text-3xl font-medium mt-7 text-slate-300">
            Empowering Innovation Through Software Solutions. Embrace the power
            of technology with purpose. Let us transform your ideas into
            reality.
          </h3>
          {/* <button className="button">Read more</button> */}
        </div>

        <div className=" relative w-full h-fit lg:h-full">
          <div className="w-full h-full absolute top-8 lg:top-0 left-0 flex items-center justify-center">
            <iframe
              className="rounded-lg w-full lg:w-[550px] 2xl:w-[700px] h-[200px] lg:h-[350px] 2xl:h-[430px] mt-10"
              title="YouTube Video"
              src={`https://www.youtube.com/embed/fTvGvGIrcjg`}
              allowFullScreen
            ></iframe>
          </div>
          <img
            src={heroImg}
            alt="ai"
            className=" w-[250px] lg:w-[450px] 2xl:w-[550px]"
          />
        </div>
        {/* <Tilt className="parallax-effect" perspective={500}></Tilt> */}
      </div>
    </section>
  );
};

export default Banner;
