import { useState } from "react";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const [name, setName] = useState("");
  const [emai, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [budget, setBudget] = useState("");
  const [message, setMessage] = useState("");

  var templateParams = {
    name: name,
    phone: phone,
    email: emai,
    budget: budget,
    message: message,
  };

  console.log(templateParams);

  const handleSubmit = () => {
    emailjs
      .send(
        "service_uey4qm4",
        "template_0gbpylj",
        templateParams,
        "e5U-yqmA2PiYV77zN"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
          setName("");
          setEmail("");
          setBudget("");
          setPhone("");
          setMessage("");
        },
        (error) => {
          console.log("FAILED...", error);
        }
      );
  };

  return (
    <section className="bg-[#01002B] -mt-16 2xl:-mt-16 flex items-center justify-center h-screen">
      <div className=" container mx-auto px-4">
        <div className=" max-w-2xl mx-auto">
          <h1 className="text-center font-medium lg:text-5xl text-3xl lg:mt-7 text-primary font-serif mb-10">
            Got a Project in Mind?
          </h1>
          {/* <form action=""> */}
          <div className=" flex justify-between items-center gap-5 mb-5">
            <div className="relative pt-6 block overflow-hidden border-b border-gray-200 bg-transparent focus-within:border-primary w-full">
              <input
                value={name}
                onChange={(e) => setName(e.target.value)}
                type="text"
                id="UserEmail"
                placeholder="Your Name"
                className="peer h-9 w-full border-none bg-transparent p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 text-lg"
              />

              <span className="absolute start-0 top-2 -translate-y-1/2 text-lg text-gray-400 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-lg peer-focus:top-2 peer-focus:text-lg z-10">
                Type your name
              </span>
            </div>
            <div className="relative pt-6 block overflow-hidden border-b border-gray-200 bg-transparent focus-within:border-primary w-full">
              <input
                value={emai}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder="Your Email"
                className="peer h-9 w-full border-none bg-transparent p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 text-lg"
              />

              <span className="absolute start-0 top-2 -translate-y-1/2 text-lg text-gray-400 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-lg peer-focus:top-2 peer-focus:text-lg z-10">
                Type your Email
              </span>
            </div>
          </div>
          <div className=" flex justify-between items-center gap-5 mb-5">
            <div className="relative pt-6 block overflow-hidden border-b border-gray-200 bg-transparent focus-within:border-primary w-full">
              <input
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                type="text"
                placeholder="Your Number"
                className="peer h-9 w-full border-none bg-transparent p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 text-lg"
              />

              <span className="absolute start-0 top-2 -translate-y-1/2 text-lg text-gray-400 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-lg peer-focus:top-2 peer-focus:text-lg z-10">
                Type your Number
              </span>
            </div>
            <div className="relative pt-6 block overflow-hidden border-b border-gray-200 bg-transparent focus-within:border-primary w-full">
              <input
                value={budget}
                onChange={(e) => setBudget(e.target.value)}
                type="email"
                placeholder="Your Budget"
                className="peer h-9 w-full border-none bg-transparent p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 text-lg"
              />

              <span className="absolute start-0 top-2 -translate-y-1/2 text-lg text-gray-400 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-lg peer-focus:top-2 peer-focus:text-lg z-10">
                Type your Budget
              </span>
            </div>
          </div>
          <div className=" flex justify-between items-center gap-5 mb-5">
            <div className="relative pt-6 block overflow-hidden border-b border-gray-200 bg-transparent focus-within:border-primary w-full">
              <input
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                type="text"
                placeholder="Your Message"
                className="peer h-9 w-full border-none bg-transparent p-0 placeholder-transparent focus:border-transparent focus:outline-none focus:ring-0 text-lg"
              />

              <span className="absolute start-0 top-2 -translate-y-1/2 text-lg text-gray-400 transition-all peer-placeholder-shown:top-1/2 peer-placeholder-shown:text-lg peer-focus:top-2 peer-focus:text-lg z-10">
                Your Message
              </span>
            </div>
          </div>
          <div className=" text-center">
            <button className="button" onClick={handleSubmit}>
              Send Message
            </button>
          </div>
          {/* </form> */}
        </div>
      </div>
    </section>
  );
};

export default Contact;
